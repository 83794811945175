/* Example Styles for React Tags*/
.app {
    padding: 40px;
    text-align: center;
  }
  
  /* Example Styles for React Tags*/
  
  .container {
    margin: auto;
    width: 50%;
  }
  .ReactTags__tags {
    position: relative;
  }
  
  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    font-size: 16px;
    border: 1px solid #eee;
    height: 40px;
    margin-left: 0px;
    border-radius: 5px;
    padding: 3px;
    min-width: 400px;
    padding-left: 15px;
  }
  
  .ReactTags__editInput {
    border-radius: 1px;
  }
  
  .ReactTags__editTagInput {
    display: inline-flex;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    background: #f88d8d;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 5px 5px;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
    z-index: 999;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
    margin-left: 5px;
  }